import ImageGallery from "react-image-gallery";
import gal from "./../data/gallery.json"
import YouTubeCarousel from './youTubeCarousel';

const images = gal;
function gallery() {
    const videos = [
        'hmKTNUh36dY',
        'YYBTb4TmY1c',
        'FJvWZ6nvn6k',
       
        // Add more video IDs as needed
      ];
    return <section className='bg-grey align-page pb-5'>
        <h3 className=" bb-1 pb-3 uppercase logo-heading text-left" >Take a glimpse of our shows</h3>
        <ImageGallery items={images} showThumbnails={false} />;
        {/* <h3 className=" bb-1 pb-3 uppercase logo-heading text-left" >Videos</h3> */}

        {/* <div className="col-md-12 d-flex">
            <div className="col-md-6">
                <video width="100%" height="400" controls >
                    <source src="./images/videos/video1.mp4" type="video/mp4" />
                </video></div>
            <div className="col-md-6">
                <video width="100%" height="400" controls >
                    <source src="./images/videos/video1.mp4" type="video/mp4" />
                </video>
            </div>

        </div> */}
        <div className="text-center col-sm-12 col-md-12 col-lg-12 mx-auto">
            <h3 className="  pb-3 uppercase logo-heading text-left" >OR
            </h3>
            <h4 className=" text-left   text-white " > Watch our performances on youtube!</h4>
            <YouTubeCarousel videos={videos} />
        </div>
        
    </section >

}

export default gallery;