// YouTubeCarousel.js

import React, { useState } from 'react';

const YouTubeCarousel = ({ videos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  const prevVideo = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? videos.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="carousel-container position-relative">
      <div className="video-container col-sm-12 col-md-12 col-lg-12">
        <iframe
          width="281px"
          height="500px" className="col-sm-12 col-md-12 col-lg-12 my-4"
          src={`https://www.youtube.com/embed/${videos[currentIndex]}`}
          title="YouTube Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <button class="image-gallery-icon image-gallery-left-nav top-100 p-0" onClick={prevVideo}>Prev</button>
      <button class="image-gallery-icon image-gallery-right-nav top-100 p-0" onClick={nextVideo}>Next </button>
    </div>
  );
};

export default YouTubeCarousel;
