function header() {
    return <header className='text-center '>


        <div className="parallax-container section-1">
            <h1 className='ml-24'>Groove to the best beats in Bangalore
                <div> <a className="text-none" href="tel:918337051858"><button className='malhar-button'>Book us now</button></a></div>
            </h1>
        </div>
        <div className="parallax-content p-0">
            <h3 className='logo-heading-top '>
                <img alt='logo' className="logobg" src="./images/malharLogo.png" width={180} height={'auto'}/>
                <div> The Band Malhar</div>
            </h3>

        </div>
        {/* Malhar is a Bollywood Hard Rock band from Bangalore, India. The band was formed when a group of
melomaniacs crossed paths with each other and decided to stick together for their shared passion
towards the craft. The band's sound is inspired by genres like Alternate Rock, Punk, Blues Rock and
Bollywood Fusion. Their unique brand of entertainment fuses hard hitting grooves, wailing guitars and
soulful vocals with high energy performance and a versatile set that will definitely make you want to
stomp your feet. */}
    </header>
}
export default header;