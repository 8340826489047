// import img from './../assets/images/members/badal.jpg';
import data from './../data/members.json';


function team() {
    return <section className="bg-black align-page">
        <h3 className=" bb-1 pb-3 uppercase logo-heading text-left" >About us</h3>
        <div className='align-page mx-auto p-0 p-3 text-center text-white'>
            <p>Malhar is a Bollywood Hard Rock band from Bangalore, India. The band was formed when a group of
            melomaniacs crossed paths with each other and decided to stick together for their shared passion
            towards the craft. The band's sound is inspired by genres like Alternate Rock, Punk, Blues Rock and
            Bollywood Fusion. Their unique brand of entertainment fuses hard hitting grooves, wailing guitars and
            soulful vocals with high energy performance and a versatile set that will definitely make you want to
            stomp your feet.
            </p>
        </div>
        <h3 className=" bb-1 pb-3 uppercase logo-heading text-left" >Introducing the Band members</h3>


        <div className='p-0 bg-black align-page member-list '>
            {data.map(item => (
                <div className="card p-1 col-lg-4 col-md-4 col-sm-6 col-xs-12 bg-black" >
                    <a href={item.instagramLink} target='_blank' rel="noreferrer" > <img src={item.src} className="card-img-top" alt="..." />
                    </a>
                    <div className="card-body text-white">
                        <h5 className="card-title">{item.title}</h5>
                        <p className="card-text">{item.description}</p>
                        <p className="card-text">{item.subDescription}</p>
                    </div>
                </div>
            ))}
        </div>
    </section>
}
export default team;