import React from 'react';
import Header from './components/header';
import Team from './components/team';
import Gallery from './components/gallery';
import ContactUs from './components/contactUs';
import Footer from './components/footer';
function App() {


  return (
    <div>
      <Header />
      <Team />
      <Gallery />
      <ContactUs />
      <Footer/>
    </div>
  );
}

export default App;
